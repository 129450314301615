<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link tag="a" to="/" class="text-dark">
        <i class="fas fa-home h2"></i>
      </router-link>
    </div>
    <div v-if="user" class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-primary">
                <div class="text-primary text-center p-4">
                  <h5 class="text-white font-size-20">
                    Definição de nova senha
                  </h5>
                  <p class="text-white-50">
                    Olá {{ user.username }}, insira abaixo a sua nova senha
                  </p>
                </div>
              </div>
              <div class="card-body p-4">
                <div class="p-3">
                  <form @submit.prevent="onSubmit" class="form-horizontal mt-4">
                    <b-form-group label="Senha" label-for="password">
                      <b-form-input
                        id="password"
                        type="password"
                        v-model="form.password"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Confirmar senha" label-for="cpassword">
                      <b-form-input
                        id="cpassword"
                        type="password"
                        v-model="form.confirmPassword"
                        required
                        :class="{
                          'is-invalid':
                            form.confirmPassword.length > 0 &&
                            !$v.form.confirmPassword.sameAsPassword,
                        }"
                      ></b-form-input>
                      <div
                        v-if="!$v.form.confirmPassword.sameAsPassword"
                        class="invalid-feedback"
                      >
                        <span>
                          Senhas não correspondentes!
                        </span>
                      </div>
                    </b-form-group>

                    <div class="form-group row mb-0">
                      <div class="col-12 text-right">
                        <button class="btn btn-primary w-md" type="submit">
                          Confirmar senha
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                ©
                {{ new Date().getFullYear() }}
                QuestõesPRO.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/api";

import appConfig from "@/app.config";

import { sameAs } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Definição de senha",
    meta: [{ name: "description", content: appConfig.description }],
  },

  data() {
    return {
      user: false,

      form: {
        password: "",
        confirmPassword: "",
      },
    };
  },

  created() {
    this.temporaryLogin();
  },

  beforeDestroy() {
    this.logout();
  },

  validations: {
    form: {
      confirmPassword: { sameAsPassword: sameAs("password") },
    },
  },

  methods: {
    temporaryLogin() {
      api.setToken(this.$route.query.token);

      api
        .me()
        .then((user) => {
          this.user = user;
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado!", "error");
        });
    },

    logout() {
      api.setToken(null);
    },

    onSubmit() {
      if (!this.form.password || !this.form.confirmPassword) {
        return this.$swal("Opa", "Você deve colocar sua nova senha!", "error");
      }

      if (this.form.password !== this.form.confirmPassword) {
        return this.$swal("Opa", "Senhas não correspondentes", "error");
      }

      api
        .updateUser(this.user.id, { password: this.form.password })
        .then(() => {
          this.$swal("Parabéns", "Senha alterada com sucesso!", "success");
          return this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado!", "error");
        });
    },
  },
};
</script>
